


.page_bg {  
    padding: 10px;
    padding-bottom: 15px;
    border: 2px solid var(--green-alpha50);
    margin: 5px;
    margin-top: 8rem;
    font-family: Roboto;
    background-color:  var(--white-alpha-40);
    
  }

  h3{
    margin-bottom: 5px;
  }

  p{
    margin-bottom: 0px;
    margin-top: 0px;
    padding-left: 5px;
    padding-right: 5px;
  }

.h1_Style{
    margin: 0;
    margin-top: 5px;
    margin-bottom: 10px;
    padding: 10px;
    border: 2px solid var(--black);
    background-color: var(--header);
    border-radius: 2px;
    text-align: center;
    font-size: 1.5rem;
    letter-spacing: 1.0px;
}

.comp_name {
    color: var(--grey-dark );
}

.h2_Style {
    margin: 0;
    margin-top: 40px;
    margin-bottom: 10px;
    padding: 10px;
    border: 1px solid var(--green-alpha50);
    background-color: var(--card-bg-dark-50);
    border-radius: 2px;

    font-size: 1.2rem;
    letter-spacing: 1.0px;
}

.tablePrivacy{
    margin-top: 25px;
    margin-bottom: 35px;
    border: 2px solid var(--green);
    background-color: var(--green-alpha15);
    border-collapse: collapse;
    font-size: 0.8rem;
}

.tablePrivacy td, .tablePrivacy th {
    
    border: 1px solid var(--green-alpha50);
    padding: 10px;
  }

.tablePrivacy tr:nth-child(even){background-color: var(--green-alpha15);}

li{
    padding-left: 20px;
    padding-right: 20px;
}

span{
 font-weight: 500;   
}