

.AppContainer{
 display       : flex;
 min-height: 83vh;  /* magic number viewport height - height of footer */
 
 /* flex-direction: column; */
 background-image: url("../assets/background_image.svg"); 
 background-size: cover;
 background-repeat: no-repeat;
 background-attachment: fixed; 
 /* border: 1px solid blue; */
}

.AppMobile {
  width: 65%;
  max-width: 750px; 
}

.AppPC{
  opacity: 0.2;
  width: 35%;
  min-width: 300px;
  margin-top: 8rem;  /* magic number the height of the fixed header */
  margin-bottom: 1rem;
  background-color: var(--logo-grey); 
  margin-left: auto;
  margin-right: 1.5rem;  /* align this with the header */
  padding: 10px;
  min-height: 100px;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  border-radius: 8px;
  border: 3px solid var(--black-alpha_50);
}


/*
  targetting mobile phone first.
  change at 755px so that even in landscape mode
  will only show the mobile app layout.

  Or set to 750px so that on mobile users can 
  experiance the PC layout screen. Not very 
  user friendly thou.
*/

@media screen and (max-width: 750px){
  .AppMobile{
    width: 100%
  }

  .AppPC{
    display: none;
  }
}


.App-content {
  flex: 1 0 auto;
}


