

.App-content {
    flex: 1 0 auto;
  }
  
  
  .App-logo {
    display: block;
    margin: 2.5rem;
    margin-left: auto;
    margin-right: auto;
  
    width         : 80%;
    pointer-events: none;
    max-width     : 600px;
  
  }
  
  @media (prefers-reduced-motion: no-preference) {
    .App-logo {
      animation: App-logo-spin infinite 25s linear;
    }
  }
  
  .App-text {
    margin: auto;
    margin-top    : 7rem;
    font-family   : Sono;
    font-size     : calc(40px + 6vmin);
    text-align    : center;
    font-weight   : 400;
    text-transform: uppercase;
    letter-spacing: 0.5px;
    color         : var(--grey-blue);
    margin-bottom : 10vh;
    line-height   : calc(30px + 8vmin);
    max-width     : 90%;
  }
  
  .App-text-punchline {
    margin: 0 auto;
    margin-bottom: 1vh;
    position: relative;
    font-family   : 'Handwritten';
    font-size     : calc(20px + 6vmin);
    text-align    : center;
    line-height   : calc(20px + 10vmin);
    letter-spacing: 4px;
    color         : var(--green);
    transform     : rotate(-3deg);
    max-width     : 80%;
  }
  
  
  @keyframes App-logo-spin {
    from {
      transform: rotate(0deg);
    }
  
    to {
      transform: rotate(360deg);
    }
  }