
@import-normalise;  /* do a css reset */

:root {
  /* colours */

  /* white */
  --white         : rgba(255, 255, 255, 1);
  --white-alpha-40: rgba(255, 255, 255, 0.4);
  --white-alpha-20: rgba(255, 255, 255, 0.2);
  --white-alpha-10: rgba(255, 255, 255, 0.1);

  /* black */
  --black                     : rgba(0, 0, 0, 1);
  --black-alpha-80            : rgba(0, 0, 0, 0.8);
  --black-alpha_15            : rgba(0, 0, 0, 0.15);
  --black-alpha_25            : rgba(0, 0, 0, 0.25);
  --black-alpha_50            : rgba(0, 0, 0, 0.50);
  --background-gradient-colour: #cac9b3;

  /* yellow */
  --bright-yellow: rgba(246, 251, 11, 1);
  --logo-yellow  : rgba(224, 228, 25, 1);
  --yellow-light : rgba(245, 228, 137, 1);
  --yellow-shade : rgba(202, 201, 179, 1);

  /* orange */
  --orange  : rgba(255, 152, 0, 1);
  --orange-1: rgba(255, 193, 7, 1);
  --orange-2: rgba(255, 228, 145, 1);
  --orange-3: rgba(255, 244, 212, 1);

  /* headers and footers */
  --footer         : rgba(65, 66, 58, 1);
  --sub-title-bg   : rgba(128, 129, 131, 1);
  --header         : rgba(170, 168, 168, 1);
  --card-bg-dark   : rgba(187, 188, 191, 1);
  --card-bg-dark-50   : rgba(187, 188, 191, 0.5);
  --card-bg-neutral: rgba(217, 217, 217, 1);
  --card-bg-light  : rgba(241, 241, 241, 1);

  /* grey blue */
  --grey-blue  : rgba(55, 71, 79, 1);
  --grey-blue-1: rgba(120, 144, 156, 1);
  --grey-blue-2: rgba(164, 179, 187, 1);
  --grey-blue-3: rgba(205, 217, 224, 1);

  /* blue */
  --blue  : rgba(29, 25, 228, 1);
  --blue-1: rgba(110, 107, 234, 1);
  --blue-2: rgba(170, 169, 237, 1);
  --blue-3: rgba(217, 216, 235, 1);

  /* warning red */
  --warning-red        : rgba(212, 32, 32, 1);
  --warning-red-alpha50: rgba(212, 32, 32, 0.5);
  --warning-red-alpha15: rgba(212, 32, 32, 0.15);

  /* green all good */
  --green        : rgba(7, 122, 39, 1);
  --green-alpha50: rgba(7, 122, 39, 0.5);
  --green-alpha25: rgba(7, 122, 39, 0.25);
  --green-alpha15: rgba(7, 122, 39, 0.15);


  --logo-green        : rgba(7, 122, 39, 1);
  --logo-yellow       : rgba(224, 228, 25, 1);
  --logo-grey         : rgba(217, 217, 217, 1);
  --logo-bright-yellow: rgba(246, 251, 11, 1);
  --grey-dark         : rgba(128, 129, 131, 1);
  --grey-dark-1       : rgba(187, 188, 191, 1);
  --light-grey        : rgba(241, 241, 241, 1);
  --light-grey_1      : rgba(217, 217, 217, 1);
  --header-grey       : hsl(0, 1%, 66%);

  --fontFamily-forum    : 'Forum', cursive;
  --fontFamily-dm_sans  : 'DM Sans', sans-serif;
  --fontFamily-Arimo    : 'Arimo';
  --fontFamily-Monospace: 'monospace';

  --fontsize-display-1 : calc(1.3rem + 6.7vw);
  --fontsize-headline-1: calc(2rem + 2.5vw);
  --fontsize-headline-2: calc(1.3rem + 2.4vw);
  --fontsize-title-1   : calc(1.6rem + 1.2vw);
  --fontsize-title-2   : 2.2rem;
  --fontsize-title-3   : 2.1rem;
  --fontsize-title-4   : calc(1.6rem + 1.2vw);
  --fontsize-body-1    : 2.4rem;
  --fontsize-body-2    : 1.6rem;
  --fontsize-body-3    : 1.8rem;
  --fontsize-body-4    : 1.6rem;
  --fontsize-label-1   : 1.4rem;
  --fontsize-label-2   : 1.2rem;

  --weight-regular : 400;
  --weight-bold    : 700;
  --weight-ext-bold: 900;

  --lineHeight-1: 1em;
  --lineHeight-2: 1.2em;
  --lineHeight-3: 1.5em;
  --lineHeight-4: 1.6em;
  --lineHeight-5: 1.85em;
  --lineHeight-6: 1.4em;

  --letterSpacing-1: 0.15em;
  --letterSpacing-2: 0.4em;
  --letterSpacing-3: 0.2em;
  --letterSpacing-4: 0.3em;
  --letterSpacing-5: 3px;

  --section-space: 70px;

  --shadow-1: 0px 0px 25px 0px rgba(0, 0, 0, 0.25);

  --radius-24    : 24px;
  --radius-circle: 50%;

  --transistion-1: 250ms ease;
  --transistion-2: 500ms ease;
  --transistion-3: 1000ms ease;

}
 
/* for debugging  */



/* *{
   outline: 1px solid red;   
} */


/* load font syles here */
@font-face {
  font-family: 'Handwritten';
  src: url(../public/fonts/HandWritten.ttf);
}

html,body{
   height: 100%;   /* make footer sticky at the bottom of the page */
}

body {
  margin: 0; 

  display: flex;     /* not sure about this */
  flex-direction: column;   /* or this */
 
  max-width: 100vw; 
 /*  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; */
}

.root-bg{
  flex: 1 0 auto;
}

/* code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
} */
